import { NgFor } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import {
  CarouselCaptionComponent,
  CarouselComponent,
  CarouselControlComponent,
  CarouselIndicatorsComponent,
  CarouselInnerComponent,
  CarouselItemComponent,
  ThemeDirective
} from '@coreui/angular';

interface IHighlightSlide {
  src: string;
  title: string;
  subtitle: string;
}

interface ISponsorSlide {
  title: string;
  logo: string;
  subtitle?: string;
  sponsorLevel?: string;
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  highlightslides: IHighlightSlide[] = [];
  sponsorslides: ISponsorSlide[] = [];

  ngOnInit(): void {
    // Define Highlight photo array

    this.highlightslides = [{
      src: './assets/images/2024/actionphotos/20241012_Wilsonville_vs_Canby_0674.jpg',
      title: 'MOVING THE BALL',
      subtitle: '#1 Mark Wieper threw for 343 yards and six touchdowns vs Canby'
    }, {
      src: './assets/images/2024/actionphotos/20241012_Wilsonville_vs_Canby_1071.jpg',
      title: 'Wildcat Defense',
      subtitle: 'The Wildcat Defense held Canby to 6 points'
    }, {
      src: './assets/images/2024/actionphotos/20241012_Wilsonville_vs_Canby_0633.jpg',
      title: 'Roman Kealoha',
      subtitle: '#9 Roman Kealoha had 101 total yards vs Canby'
    }, {
      src: './assets/images/2024/actionphotos/20241005-Wilsonville-vs-Central-21-Riddick-Molatore-strips-the-ball-from-Central-QB-JT-Girod-5.jpg',
      title: 'STRIP SACK!',
      subtitle: '#21 Riddick Molatore strips the ball from Central QB JT Girod'
    }, {
      src: './assets/images/2024/actionphotos/20241005-Wilsonville-vs-Central-7-Carter-Christiansen-and-the-defense-stuffs-a-Central-run.jpg',
      title: 'STUFFED',
      subtitle: '#7 Carter Christiansen and the defense stuffs a Central run'
    }, {
      src: './assets/images/2024/actionphotos/20240907_WilsonvillevsNelson_Mark-Wiepert-breaks-down-the-sideline_7192.jpg',
      title: 'BREAKING FREE',
      subtitle: '#1 Mark Wiepert breaks down the sideline'
    }, {
      src: './assets/images/2024/actionphotos/20240907_WilsonvillevsNelson_Nick-Summers-and-Lincoln-Mason-chase-Nelson QB Avirey Durdahl_7178.jpg',
      title: 'PRESSURE',
      subtitle: '#56 Nick Summers and #66 Lincoln Mason bringing pressure Nelson QB Avirey Durdahl'
    }]

    //=======================================================================================================
    //=======================================================================================================
    // Define Sponsors array
    this.sponsorslides = [{
      title: 'Hillyer\'s Midcity Ford',
      logo: '/assets/images/sponsors/hillyersford-16x9.jpg',
      subtitle: 'Diamond Sponsor - Scoreboard'
    }, {
      title: 'Swire Coca-Cola',
      logo: '/assets/images/sponsors/swire-coca-cola-logo-16X9.png',
      subtitle: 'Platinum Sponsor'
    }, {
      title: 'Bountiful Farms',
      logo: '/assets/images/sponsors/bountifulfarms-16x9.jpg',
      subtitle: 'Touchdown Sponsor'
    }, {
      title: 'Les Schwab - Wilsonville',
      logo: '/assets/images/sponsors/lesschwabtires-16x9.png',
      subtitle: 'Touchdown Sponsor'
    }, {
      title: 'Rupp Family Builders',
      logo: '/assets/images/sponsors/ruppfamilybuildersdark-16x9.png',
      subtitle: 'Touchdown Sponsor'
    }, {
      title: 'WPI Builds',
      logo: '/assets/images/sponsors/westernpartitionsincorporated-16x9.jpg',
      subtitle: 'Touchdown Sponsor'
    }, {
      title: 'AblePay',
      logo: '/assets/images/sponsors/able-pay-health-16x9.png',
      subtitle: 'Field Goal Sponsor'
    }, {
      title: "Active Water Sports",
      logo: "/assets/images/sponsors/active-water-sports-logo-16x9.png",
      subtitle: "Field Goal Sponsor"
    }, {
      title: 'Audi Wilsonville',
      logo: '/assets/images/sponsors/audi-wilsonville-logo-16x9.png',
      subtitle: 'Field Goal Sponsor'
    }, {
      title: 'Graham & Tooze Farm Store',
      logo: '/assets/images/sponsors/grahamandtooze-16x9.jpg',
      subtitle: 'Field Goal Sponsor'
    }, {
      title: 'Nichols Family Agency-Allstate',
      logo: '/assets/images/sponsors/nicholsfamilyagency-16x9.jpg',
      subtitle: 'Field Goal Sponsor'
    }, {
      title: 'Regenerative Orthopedic Center',
      logo: '/assets/images/sponsors/roc-regenerative-orthopedic-center-logo-16x9.png',
      subtitle: 'Field Goal Sponsor'
    }, {
      title: 'Senestraro Family Orthodontics',
      logo: '/assets/images/sponsors/senestrarofamilyorthodontics-16x9.png',
      subtitle: 'Field Goal Sponsor'
    }, {
      title: "24/7 Properties",
      logo: "/assets/images/sponsors/247properties.jpg",
      subtitle: "First Down Sponsor"
    }, {
      logo: '/assets/images/sponsors/barrierpestcontrol2.png',
      title: 'Barrier Pest Control',
      subtitle: 'First Down Sponsor'
    }, {
      title: "Bennett Accounting",
      logo: "/assets/images/sponsors/bennett-tax-accounting-logo.png",
      sponsorLevel: "First Down Sponsor"
    }, {
      title: "Elite Development Northwest, LLC",
      logo: "/assets/images/sponsors/elite-development-northwest-16x9.jpg",
      sponsorLevel: "First Down Sponsor"
    }, {
      title: 'Flawless Aesthetics',
      logo: '/assets/images/sponsors/flawless-aesthetics-logo-16x9.png',
      subtitle: 'First Down Sponsor'
    }, {
      title: "Innovative Dance",
      logo: "/assets/images/sponsors/innovativedance.jpg",
      sponsorLevel: "First Down Sponsor"
    }, {
      title: "Interstate Roofing",
      logo: "/assets/images/sponsors/interstateroofing-16x9.jpg",
      sponsorLevel: "First Down Sponsor"
    }, {
      title: 'Jay Puppo State Farm Insurance',
      logo: '/assets/images/sponsors/state-farm-jake-puppo-16x9.png',
      subtitle: 'First Down Sponsor'
    }, {
      title: 'Kyle Bunch - Goosehead Insurance',
      logo: '/assets/images/sponsors/kyle-bunch-goosehead-insurance-16x9.jpg',
      subtitle: 'First Down Sponsor'
    }, {
      title: "Lance/'s Superior Auto Services",
      logo: '/assets/images/sponsors/lancessuperiorautoservice.jpg',
      subtitle: 'First Down Sponsors'
    }, {
      title: 'Laue Team Real Estate',
      logo: '/assets/images/sponsors/laueteamrealestate.jpg',
      subtitle: 'First Down Sponsor'
    }, {
      title: 'McCounnaughey Real Estate',
      logo: '/assets/images/sponsors/mcconnaughey-real-estate.webp',
      subtitle: 'First Down Sponsor'
    }, {
      title: 'McDonalds Greentree Enterprises',
      logo: '/assets/images/sponsors/mcdonalds.jpg',
      subtitle: 'First Down Sponsor'
    }, {
      title: "Parker Johnstone's Wilsonville Honda",
      logo: '/assets/images/sponsors/parker-johnstones-wilsonville-honda-white.png',
      subtitle: 'First Down Sponsor'
    }, {
      title: 'Stafford Beverage',
      logo: '/assets/images/sponsors/stafford-beverage-16x9.jpg',
      subtitle: 'First Down Sponsor'
    }, {
      title: 'Therapeutic Associates Physical Therapy',
      logo: '/assets/images/sponsors/therapeutic-associates-physical-therapy-logo-16x9.png',
      subtitle: 'First Down Sponsor'
    }, {
      title: "Tonkin Wilsonville Nissan",
      logo: "/assets/images/sponsors/tonkinnissan-16x9.jpg",
      sponsorLevel: "First Down Sponsor"
    }, {
      title: "Willamette Falls Financial",
      logo: "/assets/images/sponsors/willamette-falls-financial-16x9.jpg",
      sponsorLevel: "First Down Sponsor"
    }, {
      title: "Wilsonville Carpet and Tile",
      logo: "/assets/images/sponsors/wilsonville-carpet-tile-16x9.png",
      sponsorLevel: "First Down Sponsor"
    }, {
      title: "Wilsonville Orthodontics - Dr. Restic",
      logo: "/assets/images/sponsors/wilsonvilleorthodontics-16x9.png",
      sponsorLevel: "First Down Sponsor"
    }, {
      title: "Inflated Dreams",
      logo: "/assets/images/sponsors/inflateddreams-16x9.jpg",
      sponsorLevel: "In Kind Sponsor"
    }, {
      title: "Joy of LIfe Chiropractic",
      logo: "/assets/images/sponsors/joyoflifechiropractic-16x9.jpg",
      sponsorLevel: "In Kind Sponsor"
    }, {
      title: "Pivot Marketing Group",
      logo: "/assets/images/sponsors/pivotmarketinggroup-16x9.jpg",
      sponsorLevel: "In Kind Sponsor"
    }, {
      title: 'UPS Store - Wilsonville',
      logo: '/assets/images/sponsors/ups-store-logo-16x9.png',
      subtitle: 'In Kind Sponsor'
    }]
  }
}
