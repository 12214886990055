import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgModule } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTableModule } from '@angular/material/table';
import { MaterialModule } from '../shared/material.module';

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrl: './links.component.scss',
})
export class LinksComponent {

  links = [
    { url: 'https://wilsonvillehighschoolfootball.gearupsports.net/', image: '/assets/images/misc/wilsonvillefootballgearstore.jpg', alt: 'Wilsonville Football Online Store', label: 'Wilsonville Football Online Store', imageDisplayWidth:'200' },
    { url: 'https://www.x.com/wvhs_football', image: '/assets/images/links/x-logo-white.png', alt: 'Wilsonville Football on X/Twitter', label: 'Wilsonville Football on X/Twitter', imageDisplayWidth:'100' },
    { url: 'https://www.facebook.com/WilsonvilleFootball', image: '/assets/images/links/facebook-logo-secondary.png', alt: 'Wilsonville Football on Facebook', label: 'Wilsonville Football on Facebook', imageDisplayWidth:'100' },
    { url: 'https://www.instagram.com/wvhs_football', image: '/assets/images/links/instagram-logo.png', alt: 'Wilsonville Football on Instagram', label: 'Wilsonville Football on Instagram', imageDisplayWidth:'100' },
    { url: 'https://michael-williams.smugmug.com/', image: '/assets/images/links/triplem-logo.jpg', alt: 'Triple M Photography', label: 'Triple M Photography', imageDisplayWidth:'200' },
    { url: 'https://gmartman.smugmug.com/Sports/High-School', image: '/assets/images/links/gregartmanphotography-logo.jpg', alt: 'Greg Artman Photography', label: 'Greg Artman Photography', imageDisplayWidth:'200' },
    { url: 'https://www.aktivate.com/', image: '/assets/images/links/aktivate-logo.png', alt: 'Aktivate', label: 'Aktivate', imageDisplayWidth:'200' },
    { url: 'https://www.maxpreps.com/high-schools/wilsonville-wildcats-(wilsonville,or)/football/home.htm', image: '/assets/images/links/maxpreps-logo.jpg', alt: 'Max Preps', label: 'Max Preps', imageDisplayWidth:'200' },
    { url: 'https://247sports.com', image: '/assets/images/links/247sports-logo.png', alt: '247 Sports', label: '247 Sports', imageDisplayWidth:'200' },
    { url: 'https://www.hudl.com/team/22303/highlights/70995817', image: '/assets/images/links/hudl-logo.png', alt: 'Wilsonville Football on HUDL', label: 'Wilsonville Football on HUDL', imageDisplayWidth:'200' },
    { url: 'https://www.wlwv.k12.or.us/Domain/19', image: '/assets/images/links/wvhs-logo.png', alt: 'Wilsonville High School Website', label: 'Wilsonville High School Website', imageDisplayWidth:'100' },
    { url: 'https://www.osaa.org/activities/fbl/schedules', image: '/assets/images/links/osaa-logo.jpg', alt: 'OSAA', label: 'OSAA', imageDisplayWidth:'100' },
  ];
}
