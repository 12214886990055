import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AuthInterceptor } from './auth.interceptor';
import { AuthGuard } from './auth.guard';
import { AppComponent } from './app.component';
import { AlertModule,CarouselModule,HeaderModule } from '@coreui/angular';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared/material.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';

import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { AlumniComponent } from './alumni/alumni.component';
import { DonateComponent } from './donate/donate.component';
import { ReservedseatingComponent } from './reservedseating/reservedseating.component';
import { LinksComponent } from './links/links.component';
import { AboutComponent } from './about/about.component';
import { SummercampsComponent } from './schedule/summercamps/summercamps.component';
import { JvcalendarComponent } from './schedule/jvcalendar/jvcalendar.component';
import { JvgameComponent } from './schedule/jvgame/jvgame.component';
import { VarsitycalendarComponent } from './schedule/varsitycalendar/varsitycalendar.component';
import { VarsitygameComponent } from './schedule/varsitygame/varsitygame.component';
import { CoachesComponent } from './coaches/coaches.component';
import { RosterjvComponent } from './roster/rosterjv/rosterjv.component';
import { RostervarsityComponent } from './roster/rostervarsity/rostervarsity.component';
import { SponsorComponent } from './sponsor/sponsor.component';
import { SponsorlistComponent } from './sponsorlist/sponsorlist.component';
import { ScheduleService } from './schedule/schedule.service';
import { ScheduleModule } from './schedule/schedule.module';
import { RosterService } from './roster/roster.service';
import { RosterModule } from './roster/roster.module';

@NgModule({ 
    //This is where you list the components, directives, and pipes that are part of this module.
    //Any component, directive, or pipe declared here can be used within the templates of the components that are declared in this same module.
    declarations: [
        AboutComponent,
        AlumniComponent,
        AppComponent,
        SummercampsComponent,
        CoachesComponent,
        DonateComponent,
        HomeComponent,
        HeaderComponent, 
        FooterComponent,
        // VarsitygameComponent,
        JvcalendarComponent,
        LinksComponent,
        VarsitycalendarComponent,
        ReservedseatingComponent,
        // RostervarsityComponent,
        SponsorComponent,
        SponsorlistComponent,
    ],
    //
    //This is where you specify the root component that Angular creates and inserts into the index.html host web page.
    bootstrap: [AppComponent], 
    imports: [
        AlertModule,
        CarouselModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HeaderModule,
        MaterialModule,
        MatMenuModule,
        MatToolbarModule,
        MatTabsModule,
        MatIconModule], 
    providers: [
        // AuthGuard,
        ScheduleService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        RosterService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
