/// more information about interceptors can be found here: https://javascript.plainenglish.io/angular-interceptors-a-complete-guide-7294e2317ecf
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.startsWith(environment.root_API_URL)) {
      return next.handle(request).pipe(
        tap({
          next: (event) => {
            if (event instanceof HttpResponse) {
              if (event.status === 401) {
                window.location.href = '../clientcvtapi/login/ClientCVT.Home.cls'
              }
            }
            return event;
          },
          error: (error) => {
            if ((error.status === 401) || (error.status === 404)) {
              //alert('401 - Unauthorized - Redirecting to login')
              console.log('error.status:' + error.status);
              window.location.href = '../clientcvtapi/login/ClientCVT.Home.cls'
            }
          }
        }));
    }
    else {
      return next.handle(request);
    }
  }
}

