<div class="container bg-grey" style="height: 100%;">
  <div class="row">
    <h3 class="w-100 page-title d-flex justify-content-center align-items-center flex-wrap">
      <img src="/assets/images/schools/wilsonville.png" width="50" alt="Wilsonville Logo" class="d-none d-md-inline" />
      <span class="mx-2 text-center shrink-text">
        LINKS
      </span>
      <img src="/assets/images/schools/wilsonville.png" width="50" alt="Wilsonville Logo" class="d-none d-md-inline" />
    </h3>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-4 col-lg-4 img-container text-center mx-auto" *ngFor="let link of links">
      <a [href]="link.url" class="nav-link" target="_blank">
        <img [src]="link.image" [alt]="link.alt" class="rounded img-fluid" width={{link.imageDisplayWidth}} />
        <br />{{ link.label }}
      </a>
    </div>
  </div>
  <br />
</div>