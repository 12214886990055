import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTableModule } from '@angular/material/table';
//import { MaterialModule } from 'src/app/shared/material.module';

import { IRoster } from '../roster';
import { RosterService } from '../roster.service';
@Component({
  selector: 'app-rostervarsity',
  templateUrl: './rostervarsity.component.html',
  styleUrl: './rostervarsity.component.scss'
})

export class RostervarsityComponent implements OnInit {

  errorMessage: any; displayedColumns: string[] = ['jersey', 'name', 'position', 'grade', 'height', 'weight', 'image', 'hUDL'];
  roster_dataSource = new MatTableDataSource<IRoster>()

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort

  constructor(
    private rosterService: RosterService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.rosterService.getVarsityRoster().subscribe({
      next: (roster_dataSource) => (this.roster_dataSource.data = roster_dataSource),
      error: (err) => (this.errorMessage = err),
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event?.target as HTMLInputElement).value;
    this.roster_dataSource.filter = filterValue.trim().toLowerCase();
  }
  ngAfterViewInit(): void {
    //  this.sort.sort(({ id:'id', start: 'asc'}) as MatSortable);  // sets the default sort order
    this.roster_dataSource.sort = this.sort;
    this.roster_dataSource.paginator = this.paginator;
    // //throw new Error('Method not implemented.');
  }

}