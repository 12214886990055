import { Component } from '@angular/core';

@Component({
  selector: 'app-summercamps',
  templateUrl: './summercamps.component.html',
  styleUrl: './summercamps.component.scss'
})
export class SummercampsComponent {

}
