<div class="container bg-grey" style="height: 100%">
    <div class="row">
        <h3 class="w-100 page-title d-flex justify-content-center align-items-center flex-wrap">
          <img src="/assets/images/schools/wilsonville.png" width="50" alt="Wilsonville Logo" class="d-none d-md-inline" />
          <span class="mx-2 text-center shrink-text">
            RESERVED SEATING
          </span>
          <img src="/assets/images/schools/wilsonville.png" width="50" alt="Wilsonville Logo" class="d-none d-md-inline" />
        </h3>
      </div>
    <div class="row justify-content-center">
        <div class="col-sm-12 col-md-6 col-lg-6">
            Support the Wilsonville Wildcat football program while taking advantage of reserved seating for all home
            games!
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="bg-white rounded p-3">
                <h3 class="title">
                    <span style="font-size: 0.9em;">Reserved Seating</span>
                    <span class="opake-large"> - $100</span>
                    <span class="opake-small">/yr</span>
                </h3>
                <ul class="plan-features text-left mx-auto" style="max-width: 300px;">
                    <li><i class="ion-checkmark"></i> Enjoy the same viewing each week with your own selected seat
                        location
                    </li>
                </ul>
                <br />
                <div class="d-flex justify-content-center">
                    <a class="w-50 btn btn-primary"
                        href="https://wilsonvillefootball.square.site/product/reserved-seats/31?cs=true"                        
                        target="_blank">
                        ORDER
                    </a>
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </div>
    </div>
</div>