<div class="container bg-grey">
    <div class="row">
        <h3 class="w-100 page-title d-flex justify-content-center align-items-center flex-wrap">
            <img src="/assets/images/schools/wilsonville.png" width="50" alt="Wilsonville Logo"
                class="d-none d-md-inline" />
            <span class="mx-2 text-center shrink-text">
                JV/FRESHMAN CALENDAR
            </span>
            <img src="/assets/images/schools/wilsonville.png" width="50" alt="Wilsonville Logo"
                class="d-none d-md-inline" />
        </h3>
    </div>
    <div class="row d-flex justify-content-center">
        <div class="col-sm-12 col-md-12 col-lg-8 d-flex justify-content-center">
            <a href="https://www.hillyersford.net" target="_blank" class="mx-2">
                <img src="/assets/images/sponsors/hillyersford.png" alt="Hillyer's Midcity Ford"
                    class="rounded img-fluid bg-white" style="height:100px" />
            </a>
        </div>
    </div>
    <div class="row d-flex justify-content-center">
        <div class="col-sm-12 col-md-12 col-lg-8 d-flex justify-content-center">
            <iframe
                src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=America%2FLos_Angeles&bgcolor=%23ffffff&src=Y19lODRhZDg1ZTNkMjgwMWU1MTgwZDQ1NTA2NzMwODA4MDQ5MjA5ODUxZjRjNDhmOWFlZTBhZWQ0YmRhZjc3NjM0QGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&src=ZW4udXNhI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&color=%23AD1457&color=%230B8043"
                style="border: solid 1px #777777; width:750px; height:525px;" frameborder="0" scrolling="no">
            </iframe>
            <br />
        </div>
    </div>
</div>