<div class="container text-center bg-grey">
    <div class="row">
        <h3 class="w-100 page-title d-flex justify-content-center align-items-center flex-wrap">
            <img src="/assets/images/schools/wilsonville.png" width="50" alt="Wilsonville Logo"
                class="d-none d-md-inline" />
            <span class="mx-2 text-center shrink-text">
                COACHING STAFF
            </span>
            <img src="/assets/images/schools/wilsonville.png" width="50" alt="Wilsonville Logo"
                class="d-none d-md-inline" />
        </h3>
    </div>
    <div class="row">
        <div class="col-sm-6 col-md-3 col-lg-2" style="text-align: center;">
            <img src="assets/images/2024/coaches/2024-wilsonville-football-varsity-coach-adam-guenther.jpg" 
                class="rounded img-fluid mw-100" alt="Wilsonville Head Coach Adam Guenther" width="80%"/>
            <br />
            Adam Guenther
            <div class="small">
                Head Coach<br />
                Offensive Coordinator
            </div>
            <br />
            <br />
        </div>
        <div class="col-sm-6 col-md-3 col-lg-2" style="text-align: center;">
            <img src="assets/images/2024/coaches/2024-wilsonville-football-varsity-coach-ned-walls.jpg" 
                class="rounded img-fluid mw-100" alt="Coach Ned Walls" width="80%"/>
            <br />
            Ned Walls
            <div class="small">
                Defensive Coordinator
                <br />O-Line/D-Line
            </div>
            <br />
            <br />
        </div>
        <div class="col-sm-6 col-md-3 col-lg-2" style="text-align: center;">
            <img src="assets/images/2024/coaches/2024-wilsonville-football-varsity-coach-dave-stippich.jpg" 
                class="rounded img-fluid mw-100" alt="Coach Dave Stippich" width="80%"/>
            <br />
            Dave Stippich
            <div class="small">
                Assistant Coach<br />
                RB/OLB
            </div>
            <br />
            <br />
        </div>
        <div class="col-sm-6 col-md-3 col-lg-2" style="text-align: center;">
            <img src="assets/images/2024/coaches/2024-wilsonville-football-varsity-coach-arbee-steel.jpg" 
                class="rounded img-fluid mw-100" alt="Coach Arbie Steele" width="80%"/>
            <br />
            Arbie Steele
            <div class="small">
                Assistant Coach<br />
                O-Line
            </div>
            <br />
            <br />
        </div>
        <div class="col-sm-6 col-md-3 col-lg-2" style="text-align: center;">
            <img src="assets/images/2024/coaches/2024-wilsonville-football-jv-coach-matt-humphreys.jpg" 
                class="rounded img-fluid mw-100" alt="Coach Matt Humphreys" width="80%"/>
            <br />
            Matt Humphreys
            <div class="small">
                Freshman/JV2 Head Coach
                <br />Varsity Assistant Coach
            </div>
            <br />
            <br />
        </div>
        <div class="col-sm-6 col-md-3 col-lg-2" style="text-align: center;">
            <img src="assets/images/2024/coaches/2024-wilsonville-football-varsity-coach-jake-smith.jpg" 
                class="rounded img-fluid mw-100" alt="Coach Jake Smith" width="80%"/>
            <br />
            Jake Smith
            <div class="small">
                Assistant Coach
            </div>
            <br />
            <br />
        </div>
        <div class="col-sm-6 col-md-3 col-lg-2" style="text-align: center;">
            <img src="assets/images/2024/coaches/2024-wilsonville-football-varsity-coach-micah-hatfield.jpg" 
                class="rounded img-fluid mw-100" alt="Coach Micah Hatfield" width="80%"/>
            <br />
            Micah Hatflield
            <div class="small">
                Assistant Coach
            </div>
            <br />
            <br />
        </div>
        <div class="col-sm-6 col-md-3 col-lg-2" style="text-align: center;">
            <img src="assets/images/2024/coaches/2024-wilsonville-football-jv-coach-mark-hansen.jpg" 
                class="rounded img-fluid mw-100" alt="Coach Mark Hansen" width="80%"/>
            <br />
            Mark Hansen
            <div class="small">
                Assistant Coach
                <br />
            </div>
            <br />
            <br />
        </div>
        <div class="col-sm-6 col-md-3 col-lg-2" style="text-align: center;">
            <img src="assets/images/2024/coaches/2024-wilsonville-football-varsity-coach-jake-gibson.jpg" 
                class="rounded img-fluid mw-100" alt="Coach Jake Gibson" width="80%"/>
            <br />
            Jake Gibson
            <div class="small">
                Assistant Coach
            </div>
            <br />
            <br />
        </div>
        <div class="col-sm-6 col-md-3 col-lg-2" style="text-align: center;">
            <img src="assets/images/2024/coaches/2024-wilsonville-football-jv-coach-marcus-washington.jpg" 
                class="rounded img-fluid mw-100" alt="Coach Marcus Washington" width="80%"/>
            <br />
            Marcus Washington
            <div class="small">
                Assistant Coach
                <br />
            </div>
            <br />
            <br />
        </div>
        <div class="col-sm-6 col-md-3 col-lg-2" style="text-align: center;">
            <img src="assets/images/2024/coaches/2024-wilsonville-football-jv-coach-colby-guenther.jpg" 
                class="rounded img-fluid mw-100" alt="Coach Colby Guenther" width="80%"/>
            <br />
            Colby Guenther
            <div class="small">
                JV2 Assistant Coach
                <br />
            </div>
            <br />
            <br />
        </div>
        <div class="col-sm-6 col-md-3 col-lg-2" style="text-align: center;">
            <img src="assets/images/2024/coaches/2024-wilsonville-football-jv-coach-noah-ochs.jpg" 
                class="rounded img-fluid mw-100" alt="Coach Noah Ochs" width="80%"/>
            <br />
            Noah Ochs
            <div class="small">
                JV2 Assistant Coach
                <br />
            </div>
            <br />
            <br />
        </div>
        <div class="col-sm-6 col-md-3 col-lg-2" style="text-align: center;">
            <img src="assets/images/2024/coaches/2024-wilsonville-football-jv-coach-chance-hansen.jpg" 
                class="rounded img-fluid mw-100" alt="Coach Chance Hansen" width="80%"/>
            <br />
            Chance Hansen
            <div class="small">
                JV2 Assistant Coach
                <br />
            </div>
            <br />
            <br />
        </div>
        <div class="col-sm-6 col-md-3 col-lg-2" style="text-align: center;">
            <img src="assets/images/2024/coaches/2024-wilsonville-football-varsity-manager-neveah-ramirez.jpg"
                class="rounded img-fluid mw-100" width="80%"/>
            <br />
            <div class="small">
                Neveah Ramirez
                <br />
                Team Manager
            </div>
            <br />
            <br />
        </div>
        <div class="col-sm-6 col-md-3 col-lg-2" style="text-align: center;">
            <img src="assets/images/2024/coaches/2024-wilsonville-football-varsity-manager-lindsey-luper.jpg"
                class="rounded img-fluid mw-100" width="80%"/>
            <br />
            <div class="small">
                Lindsey Luper
                <br />
                Team Manager
            </div>
            <br />
            <br />
        </div>
        <div class="col-sm-6 col-md-3 col-lg-2" style="text-align: center;">
            <img src="assets/images/2024/coaches/2024-wilsonville-football-varsity-manager-kehau-kealoha.jpg"
                class="rounded img-fluid mw-100" width="80%"/>
            <br />
            <div class="small">
                Kehau Kealoha
                <br />
                Team Manager
            </div>
            <br />
            <br />
        </div>
        <div class="col-sm-6 col-md-3 col-lg-2" style="text-align: center;">
            <img src="assets/images/2024/coaches/2024-wilsonville-football-jv-manager-blakely-brock.jpg"
                class="rounded img-fluid mw-100" width="80%"/>
            <br />
            <div class="small">
                Blakely Brock
                <br />
                JV2 Team Manager
            </div>
            <br />
            <br />
        </div>
    </div>
</div>