<div class="container bg-grey">
    <div class="row">
        <h3 class="w-100 page-title d-flex justify-content-center align-items-center flex-wrap">
          <img src="/assets/images/schools/wilsonville.png" width="50" alt="Wilsonville Logo" class="d-none d-md-inline" />
          <span class="mx-2 text-center shrink-text">
            TOUCHDOWN CLUB
          </span>
          <img src="/assets/images/schools/wilsonville.png" width="50" alt="Wilsonville Logo" class="d-none d-md-inline" />
        </h3>
      </div>
    <div class="row w-100">
        <div class="col-lg-12 col-md-12 col-sm-12 text-center">
            <div class="container">
                <h6>
                    We fundraise to ensure a safe and successful Wilsonville Football program.
                    <br />We are a non-profit 501c3.
                    <br />EIN 93-1281695
                </h6>
            </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 text-center">
            <h4>The purpose of the Wilsonville Touchdown Club (WTC) is:</h4>
            <p>To provide all third through twelfth grade football players an opportunity to enhance their football
                skills, train and develop with the best possible coaches and trainers, and develop a strong personal
                belief in the commitment to excellence in athletics and academics.</p>
            <p>Through the WTC and its commitment to the youth of Wilsonville, money will be raised to provide necessary
                materials, personnel, and support as requested by the coaches of Wilsonville Football. Our belief is for
                football to be played at its highest level requires a tremendous support group.</p>
            <p>The WTC will identify and develop that support group through its nonprofit Board of Directors and by-laws
            </p>
            <br />
        </div>
    </div>

    <div class="row justify-content-center">
        <div class="col-sm-12 col-md-6 col-lg-3">
            <div class="bg-white rounded">
                <h3 class="title">
                    TOUCHDOWN CLUB MEMBERSHIP
                    <span class="opake-large"> - $25</span>
                    <span class="opake-small">/yr</span>
                </h3>
                <ul class="plan-features">
                </ul>
                <br />
                <div class="d-flex justify-content-center">
                    <a class="w-50 btn btn-primary"
                        href="https://wilsonvillefootball.square.site/product/membership-2020-2021/30?cs=true"
                        target="_blank">
                        ORDER MEMBERSHIP
                    </a>
                </div>
                <br />
            </div>
        </div>
    </div>

    <div class="row w-100" style="border: 1px solid gray; margin: 5px 5px 5px 5px">
        <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="font-weight-bold">BOARD MEMBERS</div>
            <div>President: Tim Crowley <a
                    href="mailto:president@wilsonvillefootball.com?subject=Wilsonville%20Football--Attention%20President">email</a>
            </div>
            <div>Vice President: Sean Fee <a
                    href="mailto:vicepresident@wilsonvillefootball.com?subject=Wilsonville%20Football--Attention%20Vice%20President">email</a>
            </div>
            <div>Treasurer: Brad Christiansen <a
                    href="mailto:treasurer@wilsonvillefootball.com?subject=Wilsonville%20Football--Attention%20Treasurer">email</a>
            </div>
            <div>Secretary: Jonelle Marquis <a
                    href="mailto:secretary@wilsonvillefootball.com?subject=Wilsonville%20Football--Attention%20Secretary">email</a>
            </div>
            <div>Head Coach: Adam Guenther <a
                    href="mailto:coachguenther@wilsonvillefootball.com?subject=Wilsonville%20Football--Attention%20Coach%20Guenther">email</a>
            </div>
            <br />
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="font-weight-bold">BOARD MEMBERS-AT-LARGE</div>
            <div>Auction Chair: Beth Woodward <a
                    href="mailto:auctions@wilsonvillefootball.com?subject=Wilsonville%20Football--Attention%20Auctions">email</a>
            </div>
            <div>Special Projects: Brad Christiansen <a
                    href="mailto:treasurer@wilsonvillefootball.com?subject=Wilsonville%20Football--Attention%20Memberships">email</a>
            </div>
            <div>Sponsor Banner Coordinator: Donielle Crowley <a
                    href="mailto:banners@wilsonvillefootball.com?subject=Wilsonville%20Football--Attention%20Sponsor%20Banner%20Coordinator">email</a>
            </div>
            <div>Information Technology: Rob Bynum <a
                    href="mailto:tech@wilsonvillefootball.com?subject=Wilsonville%20Football--Attention%20Information%20Technology">email</a>
            </div>
            <div>Marketing &amp; PR: Shannon Williams <a
                    href="mailto:marketing@wilsonvillefootball.com?subject=Wilsonville%20Football--Attention%20Marketing">email</a>
            </div>
            <div>Policies &amp; Procedures Chair: Ned Walls <a
                    href="mailto:pnp@wilsonvillefootball.com?subject=Wilsonville%20Football--Attention%20Policies">email</a>
            </div>
            <div>Youth Football: Summer Moon <a
                    href="mailto:youthfootball@wilsonvillefootball.com?subject=Wilsonville%20Football--Attention%20YouthFootball">email</a>
            </div>
            <br />
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="font-weight-bold">TEAM PARENTS FOR FALL 2024</div>
            <div>Senior Class (2025) Team Parent: Becky Rodriquez <a
                    href="mailto:seniorteammom@wilsonvillefootball.com">email</a></div>
            <div>Junior Class (2026) Team Parent: Laura Elia <a
                    href="mailto:juniorteammom@wilsonvillefootball.com">email</a></div>
            <div>Sophomore Class (2027) Team Parent: OPEN <a
                    href="mailto:sophomoreteammom@wilsonvillefootball.com">email</a></div>
            <div>Freshmen Class (2028) Team Parent: Shay Putnam <a
                    href="mailto:freshman@wilsonvillefootball.com">email</a></div>
        </div>
    </div>
    <div style="display: inline-block">
        <br />
        <br />
        <br />
        Email questions to <a
            href="mailto:president@wilsonvillefootball.com?subject=Wilsonville%20Football%20Questions">president&#64;wilsonvillefootball.com</a>
    </div>
</div>