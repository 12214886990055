import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { AlumniComponent } from './alumni/alumni.component';
import { CoachesComponent } from './coaches/coaches.component';
import { DonateComponent } from './donate/donate.component';
import { HomeComponent } from './home/home.component';
import { LinksComponent } from './links/links.component';
import { ReservedseatingComponent } from './reservedseating/reservedseating.component';
import { RosterjvComponent } from './roster/rosterjv/rosterjv.component';
import { RostervarsityComponent } from './roster/rostervarsity/rostervarsity.component';
import { SummercampsComponent } from './schedule/summercamps/summercamps.component';
import {JvcalendarComponent} from './schedule/jvcalendar/jvcalendar.component';
import { JvgameComponent } from './schedule/jvgame/jvgame.component';
import { VarsitygameComponent } from './schedule/varsitygame/varsitygame.component';
import { VarsitycalendarComponent } from './schedule/varsitycalendar/varsitycalendar.component';
import { SponsorComponent } from './sponsor/sponsor.component';
import { SponsorlistComponent } from './sponsorlist/sponsorlist.component';

const routes: Routes = [];

@NgModule({
  imports: [
    RouterModule.forRoot([
      {
        path: 'about',
        component: AboutComponent,
        title: 'Touchdown Club - Wilsonville Football'
      },
      {
        path: 'alumni',
        component: AlumniComponent,
        title: 'Alumni - Wilsonville Football'
      },
      {
        path: 'summercamps',
        component: SummercampsComponent,
        title: 'Camp Schedule - Wilsonville Football'
      },
      {
        path: 'coachingstaff',
        component: CoachesComponent,
        title: 'Coaching Staff - Wilsonville Football'
      },
      {
        path: 'donate',
        component: DonateComponent,
        title: 'Donate - Wilsonville Football'
      },
      {
        path: 'jvcalendar',
        component: JvcalendarComponent,
        title: 'JV/Freshman Practice Schedule - Wilsonville Football'
      },
      {
        path: 'jvgame',
        component: JvgameComponent,
        title: 'Game Schedule JV - Wilsonville Football'
      },
      {
        path: 'varsitycalendar',
        component: VarsitycalendarComponent,
        title: 'Practice Schedule - Wilsonville Football'
      },
      {
        path: 'varsitygame',
        component: VarsitygameComponent,
        title: 'Game Schedule Varsity - Wilsonville Football'
      },
      {
        path: 'home',
        component: HomeComponent,
        title: 'Wilsonville Football'
      },
      {
        path: 'links',
        component: LinksComponent,
        title: 'Links - Wilsonville Football'
      },
      {
        path: 'reservedseating',
        component: ReservedseatingComponent,
        title: 'Reserved Seating - Wilsonville Football'
      },
      {
        path: 'jvroster',
        component: RosterjvComponent,
        title: 'JV Roster - Wilsonville Football'
      },
      {
        path: 'varsityroster',
        component: RostervarsityComponent,
        title: 'Varsity Roster - Wilsonville Football'
      },
      {
        path: 'sponsor',
        component: SponsorComponent,
        title: 'Sponsor - Wilsonville Football'
      },
      {
        path: 'sponsorlist',
        component: SponsorlistComponent,
        title: 'Sponsor - Wilsonville Football'
      },
      {
        path: '**',
        redirectTo: 'home',
        pathMatch: 'full'
      }
    ])

  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
