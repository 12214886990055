<footer>
  <div class="container bg-dark text-white text-center">
    <div class="row py-3">
      <div class="col-sm-4 col-md-4 text-center">
        Action photos generously donated by <br />
        <a href="https://michael-williams.smugmug.com/" target="_blank" class="text-white">Triple M Photography</a>
      </div>
      <div class="col-sm-4 col-md-4 text-center">
        Wilsonville Touchdown Club (WTC) is a 501c3 non-profit organization.
        <br />We fundraise to ensure a safe and successful Wilsonville Football program.
        <br />EIN 93-1281695
      </div>
      <div class="col-sm-4 col-md-4 text-center">
        <address>
          Wilsonville Touchdown Club<br />
          PO Box 3736<br />
          Wilsonville, OR 97070<br />
        </address>
        <a href="mailto:president@wilsonvillefootball.com" class="text-white" aria-label="Send an email to the president of Wilsonville Football">
          <i class="fas fa-envelope" aria-hidden="true"></i>
          <span class="sr-only">Email:</span>
          president&#64;wilsonvillefootball.com
        </a>
        <br />
        <br />
      </div>
    </div>
    Version {{currentApplicationVersion}}
  </div>
</footer>