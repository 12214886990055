import { Component } from '@angular/core';

@Component({
  selector: 'app-varsitycalendar',
  templateUrl: './varsitycalendar.component.html',
  styleUrl: './varsitycalendar.component.scss'
})
export class VarsitycalendarComponent {

}
