import { Component } from '@angular/core';

@Component({
  selector: 'app-jvcalendar',
  templateUrl: './jvcalendar.component.html',
  styleUrl: './jvcalendar.component.scss'
})
export class JvcalendarComponent {

}
